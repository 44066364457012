.carousel_container {
  width: 100%;
  position: relative;
}

.carousel_content_wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel_header {
  margin: 0 0.5%;
  margin-bottom: 60px;
  height: 96px;
  display: flex;
  justify-content: space-between;
}

.carousel_header__title {
  width: 720px;
  text-align: start;
}

.carousel_header__nav {
  display: flex;align-items: center;
}

.add-review {
  color: #34343D;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.3px;
  margin-left: 30px;
  text-decoration-style: dashed;
  border-bottom: 1px dashed #999;
  display: inline;
  cursor: pointer;
  transition: 0.3s ease all;
}
.add-review:hover {
  color: #b9b9b9;
  border-bottom-color: #b9b9b9;
}

.carousel_content {
  transition: all .2s linear;
  display: flex;
  pointer-events: auto;
  position: relative;
}
.carousel_content > * {
  flex-shrink: 0;
  flex-grow: 1;
}
.left_arrow, .right_arrow {
  border: none;
  background-color: transparent;
  height: 72px;
  width: 91px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
}
.left_arrow {
  left: 0;
  cursor: pointer;
  transition: ease-out 0.3s;
}
.right_arrow {
  right: 0;
  cursor: pointer;
  transition: ease-in 0.3s;
  border-left: none!important;
}

.disableArrow {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
}

.carousel_item {
  width: 49%;
  margin: 0 0.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel_item_content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.carousel_item_content__left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: #2D3044;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px
}

@media screen and (max-width: 900px) {
  .name_of_arrow{
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .carousel_item{
    width: 100%;
    margin: 0;
  }
  .left_arrow, .right_arrow {
    height: 52px;
    width: 71px;
  }
}

@media screen and (max-width: 768px) {
  .add-review {
    font-size: 16px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 600px) {
  .carousel_header__title {
    font-size: 24px;
    line-height: 24px;
  }
}

@media screen and (max-width: 400px) {
  .carousel_header__title {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }
  .add-review {
    font-size: 14px;
    margin-left: 0;
  }
}
