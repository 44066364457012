.what-can-deliver-root {
  padding: 160px 0;
  width: 100%;
  background-color: #F8F8F8;
}

.what-can-deliver-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.what-can-deliver-block {
  margin: 0 60px;
  max-width: 1800px;
  height: 100%;
}

.deliver-block {
  display: flex;
  background-color: white;
}

.what-can-deliver-root__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
  width: 100%;
}
.what-can-deliver-root__header h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  color: #34343D;
  text-align: start;
}

.what-can-deliver-root__content {
  width: 100%;
}

.deliver-card {
  width: 450px;
  padding: 24px;
  border-right: 1px solid rgba(220, 220, 220, 0.70);
}

.deliver-card:not(:first-child) {
  border-left: 1px solid rgba(220, 220, 220, 0.70);
}
.deliver-card:last-child {
  border-right: none;
}

.deliver-card__block {
  display: flex;
  flex-direction: column;
}

.deliver-card__description {
  display: flex;
  flex-direction: column;
  text-align: start;
  position: relative;
  background: rgba(255, 255, 255, 0.72);
  transition: 0.3s ease all;
  bottom: 0;
}

.deliver-card__desc-block {
  position: relative;
}

.deliver-card__order-btn {
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: 1s ease all;
}

.deliver-card__order-btn button{
  width: 100%;
  height: 72px;
  background-color: #122E74;
  color: #FFF;
  font-size: 20px;
  border-radius: 0;
  border: none;
  transition: 0.3s all ease;
  cursor: pointer;
}

.deliver-card__order-btn button:hover {
  background-color: #2B478F;
  color: white;
}

.deliver-card:hover .deliver-card__order-btn{
  display: block;
}

.deliver-card:hover .deliver-card__description{
  bottom: 96px;
}

.deliver-desc__header {
  margin-top: 24px;
  margin-bottom: 12px;
  color: #122E74;
  font-size: 24px;
  font-weight: 600;
}

.deliver-desc__content {
  color: #34343D;
  font-size: 16px;
  font-weight: 400;
}

.deliver-card__image {
  width: 402px;
  height: 287px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deliver-card:last-child .deliver-card__image__img {
  width: 314px;
  height: 224px;
}

.deliver-card__image__img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1920px) {
  .what-can-deliver-block {
    margin: 0;
    max-width: 1920px;
  }

  .what-can-deliver-root {
    padding: 160px 60px;
  }

  .deliver-card {
    max-width: 450px;
    width: 100%;
  }
  .deliver-card__image {
    width: auto;
  }

  .deliver-card:last-child .deliver-card__image__img {
    width: 100%;
    height: 100%;
  }
  .deliver-card__image__img {
    object-fit: contain;
  }
}
@media screen and (max-width: 1400px) {
  .deliver-card__image {
    height: 200px;
  }
}

@media screen and (max-width: 1200px) {
  .what-can-deliver-root__header h1 {
    font-size: 44px;
    line-height: 48px;
  }
  .deliver-card {
    padding: 12px;
  }
  .deliver-desc__header {
    font-size: 18px;
  }
  .deliver-desc__content {
    font-size: 14px;
  }
  .what-can-deliver-root {
    padding: 120px 60px;
  }
}

@media screen and (max-width: 992px) {
  .what-can-deliver-root {
    padding: 100px 60px;
  }
  .what-can-deliver-root__header h1 {
    font-size: 32px;
    line-height: 32px;
  }
}

@media screen and (max-width: 992px) and (min-width: 768px) {
  .deliver-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .deliver-card {
    border: none !important;
    padding: 0;
  }
  .deliver-card--first {
    border-bottom: 1px solid rgba(220, 220, 220, 0.70) !important;
    border-right: 1px solid rgba(220, 220, 220, 0.70) !important;
  }
  .deliver-card--second {
    border-bottom: 1px solid rgba(220, 220, 220, 0.70) !important;
  }
  .deliver-card--third {
    border-right: 1px solid rgba(220, 220, 220, 0.70) !important;
  }
  .deliver-card__block {
    padding: 12px;
  }
}

@media screen and (max-width: 768px) {
  .what-can-deliver-root {
    padding: 80px 60px;
  }
  .what-can-deliver-root__header {
    margin-bottom: 32px;
  }
  .deliver-block {
    flex-direction: column;
  }
  .deliver-card {
    border-right: none;
    padding: 0;
    width: 100%;
    max-width: none;
    border-left: none !important;
  }
  .deliver-card:not(:last-child) {
    border-bottom: 1px solid rgba(220, 220, 220, 0.70);
  }
  .deliver-card__image {
    height: 207px;
  }
  .deliver-card__description {
    padding: 24px;
  }
  .deliver-card:hover .deliver-card__description{
    bottom: 96px;
  }
  .deliver-desc__header {
    margin-top: 0;
  }
  .deliver-card:hover .deliver-card__order-btn {
    bottom: 24px;
    display: flex;
    justify-content: center;
  }

  .deliver-card__order-btn button{
    width: calc(100% - 48px);
  }
}

@media screen and (max-width: 600px) {
  .what-can-deliver-root {
    padding: 60px 16px;
  }
  .what-can-deliver-root__header h1 {
    font-size: 24px;
    line-height: 24px;
  }
  .deliver-desc__content {
    font-size: 13px;
  }
  .deliver-card:hover .deliver-card__description{
    bottom: 72px;
  }
  .deliver-card__order-btn button{
    height: 52px;
    font-size: 15px;
  }
}