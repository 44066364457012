.how-we-work-root {
  width: 100%;
  margin-top: 160px;
}

.we-wort-block {
  width: 1920px;
  display: flex;
  gap: 120px;
  justify-content: center;
}

.we-work__left {
  display: flex;
  width: 900px;
}

.we-work__right {
  width: 900px;
}

.we-work__left-inside {
  display: flex;
  flex-direction: column;
  gap: 79px;
  text-align: start;
}

.we-work__left-inside h1 {
  color: #34343D;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  margin-left: 60px;
}

.we-work__left-inside img {
  width: 100%;
}

.we-work__right-inside {
  display: flex;
  flex-direction: column;
  margin-right: 60px;
  text-align: start;
}

.we-work__right-inside__item {
  padding: 24px 0;
  display: flex;
  border-top: 1px solid #DCDCDC;
  border-bottom: 1px solid #DCDCDC;
  gap: 136px;
  align-items: center;
}

.we-work__right-inside__item:first-child {
  border-top: none;
}

.we-work__right-inside__item:last-child {
  border-bottom: none;
}

.we-work-item__count {
  color: #122E74;
  font-size: 32px;
  font-weight: 800;
  width: 44px;
  height: 44px;
}

.we-work-item__title {
  color: #34343D;
  font-size: 20px;
}

@media screen and (max-width: 1600px) {
  .we-work__left {
    width: 800px;
  }
  .we-wort-block {
    gap: 60px;
  }
  .we-work__right-inside__item {
    gap: 90px;
  }
}
@media screen and (max-width: 1400px) {
  .we-wort-block {
    gap: 20px;
  }
  .we-work__right-inside__item {
    gap: 30px;
  }
}

@media screen and (max-width: 992px) {
  .we-wort-block {
    flex-direction: column;
  }
  .we-work__left {
    max-width: 800px;
    width: 100%;
  }
  .we-work__right {
    max-width: auto;
    width: auto;
    margin: 0 60px;
  }
  .we-work__right-inside {
    margin: 0;
  }
  .how-we-work-root {
    margin-top: 100px;
  }
  .we-work__left-inside h1 {
    font-size: 32px;
    line-height: 32px;
  }
  .we-work__left-inside {
    gap: 40px;
  }
  .we-work__left-inside img {
    width: 90%;
  }
  .we-work-item__title {
    font-size: 18px;
  }
  .we-work-item__count {
    font-size: 28px;
  }
  .we-work__right-inside__item {
    padding: 18px 0;
  }
}

@media screen and (max-width: 768px) {
  .how-we-work-root {
    margin-top: 80px;
  }
}

@media screen and (max-width: 600px) {
  .how-we-work-root {
    margin-top: 60px;
  }
  .we-work__left-inside h1 {
    margin-left: 16px;
    font-size: 24px;
    line-height: 24px;
  }
  .we-work__left-inside {
    gap: 32px;
  }
  .we-work-item__count {
    font-size: 16px;
    width: auto;
    height: auto;
  }
  .we-work__right-inside__item {
    gap: 24px;
    align-items: start;
  }
  .we-work-item__title {
    font-size: 15px;
  }
  .we-work__right {
    margin: 0 16px;
  }
}