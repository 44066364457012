.main-view {
  width: 100%;
  height: 100vh;
  padding-top: 72px;
  position: relative;
  background-color: #F8F8F8;
}

@media screen and (min-width: 1921px) {
  .main-view {
    height: 1009px;
  }
}

.main-view-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.main-view-block {
  max-width: 1920px;
  width: 100%;
  height: 100%;
}

.main-view-content {
  display: flex;
  width: 100%;
  height: 100%; 
}

.main-offer__left {
  color: #34343D;
  margin-left: 60px;
  margin-top: 70px;
  width: calc(100% - 780px);
}

.main-offer__right {
  width: 780px;
  height: 100%;
  background-color: #122E74;
  position: relative;
}

.left-content {
  text-align: left;
  max-width: 917px;
}

.offer-button {
  width: 287px;
  height: 72px;
  background-color: #122E74;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  border-radius: 0;
  border: none;
  transition: 0.3s all ease;
  cursor: pointer;
}

.offer-button:hover {
  background-color: #2B478F;
  color: white;
}

.offer-description-1 {
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
}

.offer-description-1 span {
  color: #122E74;
}

.offer-description-2 {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #34343D;
  margin-top: 32px;
  margin-bottom: 52px;
  max-width: 445px;
}

.offers-list-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.offers-list {
  display: flex;
  justify-content: space-between;
  margin:  0 60px;
}

.offer-item {
  max-width: 360px;
  width: 100%;
  height: 200px;
  color: white;
  display: flex;
  background-color: white;
  padding: 24px 32px;
  text-align: left;
}

.offer-item--first {
  background: url(../../../../assets/main_2.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.offer-item--second,
.offer-item--third,
.offer-item--forth {
  border-right: 1px solid #DCDCDCB2;
}

.offer-item__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
  color: #122E74;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
}

.offer-item__description {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #34343D;
  margin-top: 12px;
}

.car-block {
  position: absolute;
  right: 0;
  bottom: 220px;
}
.wheel {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
}
.car-block--mobile {
  display: none;
}

@media screen and (max-width: 1920px) {
  .car-block img {
    width: 1300px;
  }
}

@media screen and (max-width: 1700px) {
  .car-block img {
    width: 1200px;
  }
  .offer-description-1 {
    width: 600px;
    font-size: 58px;
  }

  .offer-item {
    height: 180px;
    padding: 15px 20px;
  }
  .offer-item__title {
    font-size: 22px;
    line-height: 24px;
  }
  .offer-item__description {
    font-size: 14px;
  }
  .offer-description-2 {
    font-size: 18px;
    max-width: 400px;
  }
  .offer-button {
    width: 267px;
  }
}

@media screen and (max-width: 1600px) {
  .car-block {
    bottom: 200px;
  }

  .offer-item__title {
    line-height: 22px;
  }

  .main-offer__left {
    width: calc(100% - 680px);
  }
  .main-offer__right {
    width: 680px;
  }
}

@media screen and (max-width: 1500px) {
  .car-block {
    bottom: 180px;
  }

  .car-block img {
    width: 1100px;
  }
  .offer-item__title {
    font-size: 20px;
    height: 30px;
  }

  .offer-item__title svg {
    height: 30px;
  }
}

@media screen and (max-width: 1440px) {
  .offer-description-1 {
    width: 500px;
    font-size: 48px;
    line-height: 58px;
  }

  .offer-description-2 {
    font-size: 16px;
    max-width: 300px;
  }

  .offer-button {
    width: 247px;
    height: 62px;
  }
}

@media screen and (max-width: 1400px) {
  .car-block {
    bottom: 160px;
  }
  .car-block img {
    width: 1000px;
  }

  .offer-item {
    height: 160px;
  }

  .offer-description-1 {
    width: 500px;
    font-size: 48px;
    line-height: 58px;
  }
  .offer-item--first {
    display: none;
  }

  .main-offer__left {
    width: calc(100% - 580px);
  }
  .main-offer__right {
    width: 580px;
  }
}

@media screen and (max-width: 1400px) {
  .car-block img {
    width: 900px;
  }
}

@media screen and (max-width: 1300px) {
  .main-offer__left {
    margin-top: 40px;
  }
  .offer-item__title {
    line-height: 20px
  }
}

@media screen and (max-width: 1200px) {
  .car-block img {
    width: 800px;
  }
  .offer-description-1 {
    font-size: 44px;
    line-height: 48px;
  }
  .offer-description-2 {
    margin-top: 22px;
    margin-bottom: 36px;
  }

  .offer-item__description {
    font-size: 12px;
    line-height: 18px;
  }
  .offer-item__title {
    font-size: 18px;
    line-height: 16px
  }

  .main-offer__left {
    width: calc(100% - 480px);
  }
  .main-offer__right {
    width: 480px;
  }
  .offer-item {
    height: 150px;
  }
}

@media screen and (max-width: 1100px) {
  .car-block img {
    width: 700px;
  }
  .main-offer__left {
    width: calc(100% - 380px);
  }
  .main-offer__right {
    width: 380px;
  }
  .offer-item__title {
    font-size: 16px;
    line-height: 14px;
  }
  .offer-item {
    padding: 10px 15px;
    height: 140px;
  }
  .offer-item__title svg {
    height: 24px;
  }
  .offer-item__description {
    font-size: 11px;
    line-height: 16px;
  }
  .offer-button {
    width: 207px;
    height: 52px;
    font-size: 16px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1701px) and (max-height: 900px) {
  .main-view {
    height: 900px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1701px) and (min-height: 1080px) {
  .main-view {
    height: 1080px;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1501px) and (max-height: 900px) {
  .main-view {
    height: 900px;
  }
}

@media screen and (max-width: 1700px) and (min-width: 1501px) and (min-height: 980px) {
  .main-view {
    height: 980px;
  }
}

@media screen and (max-width: 1500px) and (min-width: 1401px) and (max-height: 800px) {
  .main-view {
    height: 800px;
  }
}

@media screen and (max-width: 1500px) and (min-width: 1401px) and (min-height: 980px) {
  .main-view {
    height: 980px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1201px) and (max-height: 730px) {
  .main-view {
    height: 730px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1201px) and (min-height: 850px) {
  .main-view {
    height: 850px;
  }
}

/* @media screen and (max-width: 1400px) and (min-height: 800px) {
  .main-view {
    height: 800px;
  }
} */

@media screen and (max-width: 1200px) and (min-width: 993px) and (max-height: 650px) {
  .main-view {
    height: 650px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 993px) and (min-height: 750px) {
  .main-view {
    height: 750px;
  }
}

@media screen and (max-width: 992px) {
  .offer-description-1 {
    font-size: 32px;
    line-height: 32px;
  }
  .offer-description-2 {
    font-size: 14px;
    line-height: 18px;
  }
  .car-block img {
    width: 600px;
  }
  .main-view {
    height: 550px;
    padding-top: 52px;
  }
  .offer-item__description {
    line-height: 12px;
  }
  .offer-item {
    padding: 5px 8px;
    height: 130px;
  }
  .left-content {
    max-width: 400px;
  }
}

@media screen and (max-width: 830px) {
  .car-block img {
    width: 550px;
  }
}

@media screen and (max-width: 768px) {
  .main-view {
    height: auto;
  }
  .car-block--mobile {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .offer-item--third {
    border-right: none;
  }
  .offer-item--second,
  .offer-item--third {
    border-bottom: 1px solid #DCDCDCB2;
  }
  .car-block--mobile img {
    width: 600px;
  }
  .car-block {
    display: none;
  }
  .main-view-content {
    flex-direction: column;
    height: auto;
  }
  .wheel {
    width: 50%;
  }
  .offers-list-wrapper {
    position: relative;
  }
  .main-offer__left {
    max-width: 400px;
    width: 100%;
    padding-left: 60px;
    margin-left: 0;
    margin-bottom: 32px;
  }
  .offer-description-1 {
    font-size: 32px;
    line-height: 32px;
  }
  .main-offer__right {
    width: 100%;
    height: 300px;
  }
  .offers-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .car-block--mobile img {
    width: 500px;
  }
  .car-block--mobile {
    overflow-x: hidden;
    bottom: calc(50% - 116px);
  }
  .offer-item__wrapper {
    width: 100%;
  }
  .offer-item__title {
    font-size: 18px;
    line-height: normal;
  }
  .offer-item__title svg {
    height: 35px;
  }
  .offer-item__description {
    max-width: 350px;
    font-size: 13px;
    line-height: normal;
  }
  .offers-list {
    margin: 0 16px;
  }
  .main-view {
    height: 100%;
  }
  .main-view-content {
    height: 100%;
  }
  .main-offer__left {
    height: 100%;
    margin-left: 0;
    padding-left: 16px;
    margin-bottom: 32px;
  }
  .offers-list {
    grid-template-columns: 1fr;
  }
  .offer-item {
    padding: 24px;
    max-width: none;
    height: 157px;
    border-right: none !important;
  }
  .offer-item:not(.offer-item--fifth) {
    border-bottom: 1px solid #DCDCDCB2;
  }
  .offer-button {
    font-size: 15px;
  }
  .offer-description-2 {
    font-size: 15px;
    margin-bottom: 32px;
    margin-top: 20px;
  }
}