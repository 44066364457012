.about-us-root {
  margin-top: 160px;
  width: 100%;
  height: 709px;
}

.about-us-root-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.about-us-root-block {
  max-width: 1800px;
  margin: 0 60px;
  height: 100%;
  width: 100%;
  display: flex;
}

.about-us-content {
  padding: 90px;
  padding-bottom: 0;

  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid rgba(220, 220, 220, 0.70);
  border-right: none;
  text-align: start;
}

.about-us-content__title {
  color: #34343D;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 32px;
}
.about-us-content__desc1 {
  color: #34343D;
  font-size: 20px;
  font-weight: 400;
  height: 236px;
  margin-bottom: 42px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-us-content__desc2 {
  color: #122E74;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 90px;
  width: 630px;
}

.about-us-info {
  background-color: #122E74;
}

.about-us-info__image {
  height: 439px;
}

.about-us-info__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-us-info__content {
  text-align: start;
  padding: 60px;
}

.about-us-info__content h2 {
  color: #FFF;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
}

.about-us-info__content div{
  color: #FFF;
  font-size: 20px;
  font-weight: 400;
  max-width: 570px;
  width: 100%;
}

.about-us-content__submit {
  height: 72px;
  background-color: #122E74;
  color: #FFF;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  border: none;
  transition: 0.3s ease all;
  cursor: pointer;
}

.about-us-content__submit:hover {
  background-color: #2B478F;
  color: white;
}

@media screen and (max-width: 1920px) {
  .about-us-root-block {
    margin: 0;
    max-width: 1920px;
  }

  .about-us-root {
    padding: 0 60px;
  }
}

@media screen and (max-width: 1600px) {
  .about-us-content__desc1 {
    font-size: 18px;
  }
  .about-us-content__desc2 {
    font-size: 20px;
    max-width: 555px;
    width: 100%;
  }
  .about-us-info__content div {
    font-size: 18px;
  }
}

@media screen and (max-width: 1600px) {
  .about-us-content__desc1 {
    font-size: 16px;
  }
  .about-us-content__desc2 {
    margin-top: 40px;
    margin-bottom: 0;
  }
  .about-us-info__content div {
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .about-us-root-block {
    flex-direction: column;
  }
  .about-us-content, .about-us-info {
    max-width: 100%;
    width: 100%;
  }

  .about-us-content {
    padding: 60px;
  }

  .about-us-content {
    border: 1px solid rgba(220, 220, 220, 0.70);
  }
  .about-us-root {
    height: auto;
    margin-top: 120px;
  }
  .about-us-content__desc1 {
    font-size: 20px;
  }
  .about-us-content__desc2 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 992px) {
  .about-us-root {
    margin-top: 100px;
  }
  .about-us-content__title {
    font-size: 32px;
    line-height: 32px;
  }
  .about-us-content__desc1 {
    font-size: 16px;
    margin-bottom: 0;
    height: auto;
  }
  .about-us-content__desc2 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .about-us-info__image {
    height: 360px;
  }
}

@media screen and (max-width: 768px) {
  .about-us-root {
    margin-top: 80px;
  }
}

@media screen and (max-width: 600px) {
  .about-us-content__title {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .about-us-root {
    margin-top: 60px;
    padding: 0 16px;
  }
  .about-us-content {
    padding: 24px;
  }
  .about-us-info__content {
    padding: 24px;
  }
  .about-us-info__image {
    height: 280px;
  }
  .about-us-content__desc1 {
    font-size: 15px;
  }
  .about-us-content__desc2 {
    font-size: 16px;
  }
  .about-us-content__submit {
    font-size: 15px;
    height: 52px;
    border-radius: 0;
  }

  .about-us-info__content h2 {
    font-size: 18px;
  }

  .about-us-info__content div {
    font-size: 15px;
  }
}