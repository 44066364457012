.menu-wrapper {
  height: 72px;
  align-items: center;
  background-color: white;
  justify-content: center;
  position: fixed;
  z-index: 10;
  right: 0;
  left: 0;
  border-bottom: 1px solid #DCDCDC;
}

.menu-burger {
  display: none;
}

.menu {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
  max-width: 1920px;
}

.menu-list {
  margin: 0 60px;
  width: 578px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  text-transform: uppercase;
  align-items: center;
}
.menu-list img {
  width: 50px;
}

.menu-item {
  cursor: pointer;
}
.menu-item a {
  color: #34343D;
  text-transform: none;
}
.menu-item a:hover {
  color: #122E7499;
}

.menu-actions {
  display: flex;
  height: 100%;
}

.menu-actions__item {
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s all ease;
}

.menu-actions__item:hover {
  background-color: #F5F5F5;
}

.menu-actions__item:first-child {
  border-left: 1px solid #DCDCDC;
  border-right: 1px solid #DCDCDC;
}

.menu-actions__item:last-child {
  background-color: #122E74;
  cursor: pointer;
}

.menu-actions__item:last-child:hover {
  background-color: #2B478F;
}
.menu-actions__item--phone {
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border: none;
  padding: 0;
}

@media screen and (max-width: 992px) {
  .menu-list img {
    width: 40px;
  }
  .menu-wrapper {
    height: 56px;
  }
  .menu-actions__item {
    width: 56px;
  }
}
.mobile-logo {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobile-logo {
    display: none;
  }
  .menu-list {
    display: none;
  }
  .menu-burger {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu-burger-button {
    width: 100%;
    height: 100%;
    border: none;
    border-right: 1px solid #DCDCDC;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.hamb__field {
  cursor: pointer;
}

.bar {
  display: block;
  width: 24px;
  height: 2px;
  margin: 6px auto;
  background-color: #34343D;
  transition: 0.3s;
}

.hamb__field.active .bar:nth-child(2) {
  opacity: 0;
}

.hamb__field.active .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamb__field.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}