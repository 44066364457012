.reviews-root {
  padding-top: 160px;
  width: 100%;
  background-color: #F8F8F8;
}
.reviews-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.reviews-block {
  max-width: calc(1920px + 0.5%);
  padding: 0 calc(60px - 0.5%);
  width: 100%;
}

.reviews-block .right_arrow:not(.disableArrow),
.reviews-block .left_arrow:not(.disableArrow) {
  border: 1px solid #34343D;
  background-color: #F5F5F5;
}

.reviews-block .right_arrow.disableArrow,
.reviews-block .left_arrow.disableArrow {
  border: 1px solid #34343D;
  /* background-color: #F5F5F5!important; */
}
.reviews-block .right_arrow.disableArrow svg,
.reviews-block .left_arrow.disableArrow svg{
  color: #34343D;
}

.reviews-block .right_arrow svg,
.reviews-block .left_arrow svg{
  color: #34343D;
}

.reviews-block .right_arrow:not(.disableArrow):hover,
.reviews-block .left_arrow:not(.disableArrow):hover{
  background-color: white;
}

.reviews-root .carousel_header__title {
  color: #34343D;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
}

.reviews-root .carousel_header {
  margin-bottom: 0;
  height: auto;
  margin-bottom: 40px;
  align-items: end;
}

.review-item {
  background-color: white;
  width: 100%;
  padding: 40px;
  height: calc(100% - 80px);
}

.review-item__content {
  /* max-width: 672px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;
}

.review-content__name {
  color: #34343D;
  font-size: 24px;
  font-weight: 600;
  line-height: 32.4px;
}
.review-content__grade {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  gap: 4px;
}

.review-content__comment,
.review-content__date {
  color: #34343D;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
}

.review-content__comment {
  position: relative;
}

.comment-text:not(.comment-text--all){
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
}

.show-more {
  position: absolute;
  
  color: #34343d87;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.3px;
  text-decoration-style: dashed;
  border-bottom: 1px dashed #999;
  cursor: pointer;
  transition: 0.3s ease all;
  right: 0;
}

.show-more:hover {
  color: #34343D;
}

.review-content__date {
  padding-top: 40px;
  margin-top: auto;
}

@media screen and (max-width: 992px) {
  .reviews-root {
    padding-top: 100px;
  }
  .reviews-root .carousel_header__title {
    font-size: 32px;
    line-height: 32px;
  }
  .review-content__comment, .review-content__date {
    font-size: 18px;
    line-height: 26px;
  }
}

@media screen and (max-width: 768px) {
  .reviews-root {
    padding-top: 80px;
  }
}

@media screen and (max-width: 600px) {
  .reviews-root {
    padding-top: 60px;
  }
  .reviews-block {
    padding: 0 calc(16px - 0.5%);
  }
  .review-item {
    padding: 24px;
    height: calc(100% - 48px);
  }
  .reviews-root .carousel_header__title {
    font-size: 24px;
    line-height: 24px;
  }
  .review-content__name {
    font-size: 18px;
    line-height: 24px;
  }
  .review-content__grade {
    margin-bottom: 32px;
  }
  .review-content__comment, .review-content__date {
    font-size: 15px;
    line-height: 20px;
  }
  .review-content__date {
    padding-top: 20px;
  }
  .show-more {
    font-size: 14px;
  }
}