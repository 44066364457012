.order-modal {
  width: 100%;
  max-width: 540px;
}

.order-modal .ant-modal-content {
  width: 100%;
  height: 100%;
  background-color: #122E74;
  border-radius: 0px;
  padding: 60px;
}

.order-modal-content__title {
  color: #FFF;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
  line-height: 33px;
}

.order-modal-content__input {
  width: calc(100% - 48px);
  height: 64px;
  background-color: rgba(217, 217, 217, 0.10);
  color: white;
  font-size: 20px;
  font-weight: 400;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  padding: 0 24px;
  outline: none;
}

.order-modal-content__input--error {
  border-bottom: 1px solid #E24242;
}

.order-modal-content__input::placeholder {
  color: rgba(255, 255, 255, 0.40);
}

.order-modal-content__input--error::placeholder {
  color: #E24242;
}

.order-modal-content__input:not(.order-modal-content__input--last) {
  margin-bottom: 16px;
}
.order-modal-content__input--last {
  height: 80px;
  padding: 19px 24px;
  resize: none;
}

.order-modal-content__submit {
  height: 72px;
  margin-top: 32px;
  width: 100%;
}

.order-modal-content__submit button {
  height: 100%;
  width: 100%;
  border: none;
  background-color: #FFF;
  cursor: pointer;
  transition: 0.3s ease all;
  color: #122E74;
  font-size: 20px;
}

.ant-modal-root .ant-modal-mask {
  background-color: rgba(18, 46, 116, 0.40);
}

.order-modal-content__approve {
  height: 32px;
  display: flex;
  margin-top: 32px;
}

.order-modal-content__approve div {
  color: rgba(255, 255, 255, 0.70);
  line-height: 16px;
}

.order-modal-content__approve span {
  color: white;
}

.order-modal-content__approve .custom-checkbox + label::before {
  width: 30px;
  height: 30px;
}

.error-checkbox .custom-checkbox + label::before {
  border: 1px solid #E24242;
}

.error-checkbox span, .error-checkbox div {
  color: #E24242;
}

.error-checkbox .custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
  border-color: #E24242;
}


@media screen and (max-width: 600px) {
  .order-modal {
    height: auto;
  }
  .order-modal .ant-modal-content {
    padding: 24px;
  }
  .order-modal-content__submit {
    height: 52px;
  }
  .order-modal-content__submit button {
    font-size: 15px;
  }
  .order-modal-content__input {
    height: 52px;
    font-size: 15px;
    padding: 0 20px;
  }
  .order-modal-content__input--last {
    height: 56px;
    padding: 16px 20px;
    resize: none;
  }
  .order-modal-content__approve .custom-checkbox + label::before {
    width: 24px;
    height: 24px;
  }
  .order-modal-content__approve  {
    font-size: 12px;
    max-width: 340px;
  }
}