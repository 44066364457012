.footer-root {
  width: 100%;
  background-color: #122E74;
}

.footer-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
}

.footer-content {
  padding: 60px;
  max-width: 1920px;
  width: 100%;
  display: flex;
  text-align: start;
}


.footer-add-info {
  height: 70px;
  padding: 24px 60px;
  max-width: 1920px;
  width: 100%;
  color: #FFF;
  font-size: 16px;
  text-align: start;
}

.footer-content__item {
  width: 450px;
  display: flex;
  flex-direction: column;
}

.footer-item__logo {
  width: 120px;
}

.footer-logo .footer-item__telegram {
  display: none;
}
.footer-item-block:first-child {
  margin-bottom: 36px;
}

.footer-item__title {
  margin-bottom: 12px;
  color: #FFF;
  font-size: 24px;
  font-weight: 600;
}

.footer-item__content {
  max-width: 340px;
  color: #FFF;
  font-size: 20px;
}

.requisites {
  padding-right: 30px;
}

.footer-item:last-child {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.footer-item__socials {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer-item__socials--mobile {
  display: flex;
  gap: 8px;
}

.footer-item__telegram {
  border: 1px solid #FFF;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease;
  cursor: pointer;
}
.footer-item__telegram a {
  color: white;
}


.footer-item__telegram:hover {
  background-color: #FFF;
}

.footer-item__telegram:hover a{
  color: #122E74 !important;
}

@media screen and (max-width: 1200px) {
  .footer-item__content {
    font-size: 18px;
  }
  .footer-item__telegram {
    width: 58px;
    height: 58px;
  }
}

@media screen and (max-width: 992px) {
  .footer-item__content {
    font-size: 14px;
  }
  .footer-item__logo {
    width: 100px;
  }
  .footer-item__telegram {
    width: 28px;
    height: 28px;
  }
  .footer-item__title {
    font-size: 21px;
    margin-bottom: 8px;
  }

  .footer-item__telegram svg {
    width: 24px;
    height: 24px;
  }
  .footer-item-block:first-child {
    margin-bottom: 18px;
  }
  .footer-add-info {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }
  .footer-item__title {
    margin-top: 40px;
  }
  .footer-item-block:first-child {
    margin-bottom: 0;
  }
  .footer-item__telegram {
    display: none;
    width: 48px;
    height: 48px;
  }
  .footer-content__item {
    width: 100%;
  }
  .footer-logo {
    flex-direction: row;
    justify-content: space-between;
  }
  .footer-logo .footer-item__telegram {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-item__telegram svg {
    width: 29px;
    height: 29px;
  }
}

@media screen and (max-width: 600px) {
  .footer-content {
    padding: 60px 16px;
  }
  .footer-add-info {
    padding: 24px 16px;
  }
  .footer-item__title {
    font-size: 18px;
  }
  .footer-item__content {
    font-size: 15px;
  }
  .footer-add-info {
    font-size: 15px;
  }
}