.partners-root {
  width: 100%;
  padding: 0 60px;
  margin-top: 43px;
  height: 171px;
}

.partners-root-block {
  height: 100%;
  width: 1920px;
  display: flex;
  justify-content: center;
}

.partners-root-block__item {
  height: 100%;
  max-width: 450px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(220, 220, 220, 0.70);
  border-bottom: 1px solid rgba(220, 220, 220, 0.70);
  border-left: 1px solid rgba(220, 220, 220, 0.70);
  background: #FFF;
}
.partners-root-block__item:last-child {
  border-right: 1px solid rgba(220, 220, 220, 0.70);
}

.partners-root-block__item img {
  width: 100%;
  height: 100%;
  max-height: 100px;
  object-fit: contain;
}

@media screen and (max-width: 1400px) {
  .partners-root-block__item img {
    max-width: 200px;
  }
}

@media screen and (max-width: 768px) {
  .partners-root {
    padding: 0 16px;
    height: auto;
  }
  .partners-root-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .partners-root-block__item {
    border-right: 1px solid rgba(220, 220, 220, 0.70);
    padding: 6px 10px;
    height: 96px;
  }
}

@media screen and (max-width: 600px) {
  .partners-root {
    padding: 0 16px;
  }
}