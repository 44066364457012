.contacts-root {
  padding: 160px 60px;
  width: 100%;
  text-align: start;
  background-color: #F8F8F8;
}

.contacts-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.contacts-block {
  max-width: 1800px;
  width: 100%;
}
.contacts-block h1 {
  color: #34343D;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 60px;
}

.contacts-content {
  display: flex;
}

.contacts-info {
  max-width: 900px;
  width: 100%;
  background-color: white;
  height: 530px;
  display: flex;
  flex-direction: column;
}

.contacts-info__row {
  display: flex;
  height: 265px;
}

.contacts-info__row:not(:last-child) {
  border-bottom: 1px solid rgba(220, 220, 220, 0.70);
}

.contacts-info__item {
  width: 50%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contacts-info__item:not(:last-child) {
  border-right: 1px solid rgba(220, 220, 220, 0.70);
}

.contacts-info__item h3 {
  color: #122E74;
  font-size: 24px;
  font-weight: 600;
}

.phone-num-item {
  width: fit-content;
}

.contacts-info__item div {
  color: #34343D;
  font-size: 20px;
  font-weight: 400;
}

.map {
  width: 100%;
  height: 530px;
  max-width: 900px;
  /* min-width: 660px; */
}
.yandex-map {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 1680px) {
  .contacts-info__item div {
    font-size: 18px;
  }
}

@media screen and (max-width: 1440px) {
  .contacts-content {
    flex-direction: column;
  }
  .contacts-info {
    width: 100%;
    max-width: none;
    height: 412px;
  }
  .contacts-info__row {
    height: 206px;
  }
  .map {
    max-width: 1440px;
    height: 406px;
  }
}

@media screen and (max-width: 992px) {
  .contacts-root {
    padding: 100px 60px;
  }
  .contacts-block h1 {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 40px;
  }
  .contacts-info__item div {
    font-size: 16px;
  }
  .contacts-info__item h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .contacts-root {
    padding: 80px 60px;
  }
  .contacts-block h1 {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 40px;
  }
  .contacts-info__item div {
    font-size: 16px;
  }
  .contacts-info__item h3 {
    font-size: 20px;
  }
  .contacts-info__row {
    height: auto;
    flex-direction: column;
    border-bottom: none!important;
  }
  .contacts-info__item {
    padding: 24px;
    height: 145px;
    border-right: none!important;
    width: auto;
  }
  .contacts-info__item--second {
    padding: 0;
    display: flex;
    flex-direction: row;
  }
  .contacts-info__item--mob:first-child {
    border-right: 1px solid rgba(220, 220, 220, 0.70);
  }
  .contacts-info__item--mob {
    padding: 24px;
    width: calc(50% - 48.5px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .contacts-info {
    height: auto;
  }
  .contacts-info__item:not(.contacts-info__item--last) {
    border-bottom: 1px solid rgba(220, 220, 220, 0.70);
  }
}

@media screen and (max-width: 768px) and (min-width: 748px) {
  .contacts-info__item {
    height: 100px;
  }
  .contacts-info__item--second {
    height: 148px;
  }
}

@media screen and (max-width: 748px) and (min-width: 467px) {
  .contacts-info__item {
    height: 100px;
  }
  .contacts-info__item--second {
    height: 148px;
  }
}

@media screen and (max-width: 467px) {
  .contacts-info__item {
    height: 110px;
  }
  .contacts-info__item--second {
    height: 158px;
  }
  .contacts-info__item--mob div {
    word-break: break-all;
  }
}

@media screen and (max-width: 600px) {
  .contacts-root {
    padding: 60px 16px;
  }

  .contacts-block h1 {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 32px;
  }
  .contacts-info__item h3 {
    font-size: 18px;
  }
  .contacts-info__item div {
    font-size: 15px;
  }
}

@media screen and (max-width: 486px) {
  .address-block {
    display: flex;
    flex-direction: column;
  }

  .contacts-info__item--mob:first-child {
    padding-right: 0;
    width: calc(50% - 24px);
  }
}

@media screen and (max-width: 470px) {
  .mail-block {
    display: flex;
    flex-direction: column;
  }
}