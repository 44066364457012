.delivery-reasons-root{
  width: 100%;
  background-color: #F8F8F8;
  padding: 0 60px;
  padding-top: 160px;
}

.delivery-reasons-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.delivery-reasons-block {
  margin: 0 60px;
  max-width: 1800px;
}

.delivery-reasons__title {
  margin-bottom: 60px;
  text-align: start;
}

.delivery-reasons__title h1 {
  color: #34343D;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  width: 630px;
}

.delivery-reasons__content {
  display: flex;
}
.delivery-reasons__content-sub {
  display: flex;
  width: 100%;
}

.reason-item {
  max-width: 450px;
  width: 100%;
  height: 267px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
}

.reason-item:not(:last-child) {
  border-right: 1px solid rgba(220, 220, 220, 0.70);
}

.reason-item__header {
  display: flex;
  color: #122E74;
  font-size: 24px;
  font-weight: 600;
  align-items: center;
  gap: 32px;
  text-align: start;
}

.reason-item__header__icon-block {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #122E74;
}

.reason-item__content {
  text-align: start;
  color: #34343D;
  font-size: 16px;
  max-width: 325px;
  width: 100%;
  margin-left: 32px;
  margin-top: 32px;
}

@media screen and (max-width: 1700px) {
  .reason-item__header {
    font-size: 20px;
    gap: 10px;
  }
  .reason-item__content {
    width: auto;
    margin: 20px;
  }
}

@media screen and (max-width: 1400px) {
  .reason-item__header {
    font-size: 18px;
  }
  .reason-item__header__icon-block {
    width: 60px;
    height: 60px;
  }
  .reason-item__header__icon-block svg{
    width: 36px;
    height: 36px;
  }
  .reason-item__content {
    font-size: 14px;
  }
}

@media screen and (max-width: 992px) {
  .delivery-reasons__title h1 {
    font-size: 32px;
    line-height: 32px;
  }
  .delivery-reasons-root {
    padding-top: 100px;
  }
  .delivery-reasons__content {
    flex-direction: column;
  }
  .delivery-reasons__content-sub:first-child {
    border-bottom: 1px solid rgba(220, 220, 220, 0.70);
  }
}

@media screen and (max-width: 768px) {
  .delivery-reasons__content-sub {
    flex-direction: column;
  }
  .reason-item {
    max-width: none;
    height: auto;
    border-right: none!important;
  }
  .reason-item__content {
    max-width: none;
  }
  .reason-item:not(.reason-item--last) {
    border-bottom: 1px solid rgba(220, 220, 220, 0.70);
  }
  .delivery-reasons__content-sub {
    border-bottom: none!important;
  }
  .delivery-reasons__title h1 {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .delivery-reasons-root {
    padding-top: 80px !important;
  }
}

@media screen and (max-width: 600px) {
  .delivery-reasons-root {
    padding: 0 16px;
    padding-top: 60px !important;
  }
  .delivery-reasons__title {
    margin-bottom: 32px;
  }
  .delivery-reasons__title h1 {
    font-size: 24px;
    line-height: 24px;
  }
  .reason-item__content {
    font-size: 13px;
  }
}