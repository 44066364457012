.delivered-cars-root {
  margin-top: 160px;
  width: 100%;
  height: auto;
  background-color: #122E74;
}

.delivered-cars-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.delivered-cars-block {
  max-width: calc(1920px + 0.5%);
  margin: 160px 0;
  width: 100%;
  padding: 0 calc(60px - 0.5%);
}

.delivered-cars-block .right_arrow:not(.disableArrow):hover,
.delivered-cars-block .left_arrow:not(.disableArrow):hover {
  background-color: white;
}

.delivered-cars-block .right_arrow , .delivered-cars-block .left_arrow {
  color: white;
}

.delivered-cars-block .right_arrow:not(.disableArrow):hover svg,
.delivered-cars-block .left_arrow:not(.disableArrow):hover svg{
  color: #122E74;
}

.delivered-cars-root .carousel_header {
  align-items: end;
  margin-bottom: 40px;
}

.carousel_header__title {
  color: #FFF;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
}

.carousel_item img {
  max-width: 890px;
  width: 100%;
  height: 620px;
  object-fit: cover;
}

@media screen and (max-width: 992px) {
  .delivered-cars-root {
    margin-top: 100px;
  }
  .carousel_header__title {
    font-size: 32px;
    line-height: 32px;
  }
  .delivered-cars-block {
    margin: 100px 0;
  }
}

@media screen and (max-width: 768px) {
  .delivered-cars-root {
    margin-top: 80px;
  }
  .delivered-cars-block {
    margin: 80px 0;
  }
  .delivered-cars-root .carousel_header {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {

  .delivered-cars-block {
    margin: 60px 0;
    padding: 0 calc(16px - 0.5%);
  }
  .delivered-cars-root {
    margin-top: 60px;
  }
  .carousel_item img {
    height: 320px;
  }
  .carousel_header {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 450px) {
  .carousel_item img {
    height: 240px;
  }
}