.need-consultation-root {
  width: 100%;
  height: 762px;
}

.need-consultation-block {
  display: flex;
  height: 100%;
  width: 100%;
}

.need-consultation__image, .need-consultation__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.need-consultation__content {
  padding: 100px 90px;
  background-color: #122E74;
}

.consult-wrapper {
  max-width: 540px;
  width: 100%;
  text-align: start;
}

.consult-wrapper__question {
  color: #FFF;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 32px;
}

.consult-wrapper__desc {
  color: #FFF;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 60px;
}

.consult-wrapper__inputs {
  display: flex;
  flex-direction: column;
  max-width: 540px;
  width: 100%;  
}

.consult-wrapper__approve div {
  color: rgba(255, 255, 255, 0.70);
  line-height: 16px;
}

.consult-wrapper__approve span {
  color: white;
}

.consult-wrapper__input {
  width: calc(100% - 48px);
  height: 64px;
  background-color: rgba(217, 217, 217, 0.10);
  color: white;
  font-size: 20px;
  font-weight: 400;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  padding: 0 24px;
  outline: none;
}

.consult-wrapper__input--error {
  border-bottom: 1px solid #E24242;
}

.consult-wrapper__input::placeholder {
  color: rgba(255, 255, 255, 0.40);
}

.consult-wrapper__input--error::placeholder {
  color: #E24242;
}

.error-checkbox span, .error-checkbox div {
  color: #E24242;
}

.consult-wrapper__input:first-child {
  margin-bottom: 16px;
}

.consult-wrapper__approve {
  margin-top: 40px;
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}


.consult-wrapper__submit {
  max-width: 540px;
  width: 100%;
  height: 72px;
  background-color: white;
  color: #122E74;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  border-radius: 0;
  border: none;
  cursor: pointer;
  transition: 0.3s ease all;
}

.consult-wrapper__submit:hover {
  color: #122E74;
  background-color: #FFFFFFE5;
}

.consult-wrapper__approve div {
  max-width: 396px;
  width: 100%;
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox + label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.custom-checkbox + label::before {
  content: '';
  display: inline-block;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #c3c3c3;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}


.custom-checkbox:checked + label::before {
  border-color: #c3c3c3;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
  border-color: #c3c3c3;
}

.custom-checkbox:focus + label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.125);
}
.custom-checkbox:focus:not(:checked) + label::before {
  border-color: #c3c3c3;
}
.custom-checkbox:disabled + label::before {
  background-color: black;
}

@media screen and (max-width: 1400px) {
  .need-consultation__content {
    padding: 80px 70px;
  }
}

@media screen and (max-width: 1200px) {
  .consult-wrapper__question {
    font-size: 44px;
  }

  .consult-wrapper__desc {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .consult-wrapper__question {
    font-size: 32px;
    line-height: 32px;
  }
  .need-consultation__content {
    padding: 0 50px;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .need-consultation-root {
    height: auto;
  }
  .need-consultation-block {
    flex-direction: column-reverse;
  }
  .need-consultation__image {
    max-width: none;
  }
  .need-consultation__content {
    max-width: none;
    padding: 80px 60px;
  }
}

@media screen and (max-width: 600px) {
  .need-consultation__content {
    padding: 60px 16px;
  }
  .consult-wrapper__question {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .consult-wrapper__input {
    height: 52px;
    font-size: 15px;
    padding: 0 20px;
  }
  .consult-wrapper__desc {
    font-size: 15px;
    margin-bottom: 32px;
  }
  .custom-checkbox + label::before {
    width: 24px;
    height: 24px;
  }
  .consult-wrapper__submit {
    height: 52px;
    font-size: 15px;
  }
  .consult-wrapper__approve {
    margin-top: 24px;
    font-size: 12px;
    line-height: 14px;
  }
}