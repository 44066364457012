.menu-modal {
  position: fixed;
  top: 56px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0px;
  margin: 0px !important;
  width: 100vw !important;
  max-width: 100vw !important;
  background-color: white;
  z-index: 100;
  height: 0px;
  overflow: hidden;
  transition: 0.3s all ease;
}
.menu-modal--active {
  height: 100%;
}

.menu-modal-content {
  padding: 64px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.menu-modal-item {
  width: max-content;
}

.menu-modal-item a {
  color: #34343D;
  text-transform: none;
  font-size: 15px;
}
.menu-modal-item a:hover {
  color: #122E7499;
}

@media screen and (max-width: 600px) {
  .menu-modal-content {
    padding: 40px 16px !important;
  }
}