.price-list-root {
  width: 100%;
  height: 704px;
  padding: 160px 60px 0;
  background-color: #F8F8F8;
}

.price-list-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.price-list-block {
  max-width: 1800px;
  width: 100%;
  padding: 60px;
  background-color: #122E74;
  height: 100%;
}

.price-list__header h1{
  margin-bottom: 36px;
  color: #FFF;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  text-align: start;
}

.price-list__content {
  display: flex;
  justify-content: space-between;
  height: 350px;
}

.price-list__content__conditions__item {
  max-width: 570px;
  width: 100%;
  text-align: start;
}

.condition-item {
  padding: 24px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  width: 100%;
}

.condition-item-block {
  display: flex;
  gap: 20px;
}

.condition-item-block span {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
}

.price-list__content__conditions {
  display: flex;
  max-width: 1140px;
  width: 100%;
  gap: 40px;
}

.price-list__content__price {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  min-width: 270px;
}

.price h1 {
  color: #FFF;
  text-align: right;
  font-size: 48px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 48px;
}

.price button {
  max-width: 360px;
  width: 100%;
  height: 72px;
  background-color: white;
  color: #122E74;
  font-size: 20px;
  font-weight: 400;
  border-radius: 0;
  border: none;
  cursor: pointer;
  transition: 0.3s ease all;
}

.price button:hover {
  color: #122E74;
  background-color: #FFFFFFE5;
}

@media screen and (max-width: 1700px) {
  .price-list__content__conditions__item:first-child {
    max-width: 370px;
  }
  .price-list__content__conditions__item:last-child {
    width: 520px;
  }
}


@media screen and (max-width: 1460px) {
  .price-list-root {
    height: 100%;
  }
  .price-list__content__conditions {
    gap: 0;
    flex-direction: column;
  }
  .price-list__content {
    height: 100%;
  }
  .price-list__content {
    gap: 20px;
  }
  .price-list__content__conditions__item {
    max-width: 670px!important;
    width: 100%!important;
  }
}

@media screen and (max-width: 1060px) {
  .price-list__content {
    flex-direction: column;
    gap: 32px;
  }
  .price-list__content__conditions__item {
    max-width: 100%!important;
    width: 100%!important;
  }
  .price {
    width: 100%;
  }
  .price button {
    max-width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .price-list-root {
    padding: 100px 60px 0;
  }
  .price-list__header h1 {
    font-size: 32px;
    line-height: 32px;
  }
  .condition-item {
    padding: 18px 0;
  }
  .price h1 {
    font-size: 32px;
    line-height: 32px;
  }
  .price button {
    height: 64px;
  }
}

@media screen and (max-width: 768px) {
  .price-list-root {
    padding-top: 80px;
  }
}

@media screen and (max-width: 600px) {
  .price-list-root {
    padding: 60px 16px 0;
  }
  .price-list-block {
    padding: 24px;
  }
  .price-list__header h1 {
    font-size: 24px;
    line-height: 24px;
  }
  .condition-item-block span {
    font-size: 13px;
  }
  .condition-item-block svg {
    width: 18px;
    height: 18px;
  }
  .price h1 {
    margin-bottom: 40px;
  }
  .price button {
    height: 54px;
  }
}